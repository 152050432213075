<template>
  <div>
    <b-card>
      <h3 style="text-align: center">Manage User Rights</h3>
      <b-row class="mt-1">
        <b-col md="6" v-for="(tp, index) in formTypes" :key="type">
          <hr />
          <b-row>
            <b-col md="4">
              <h5>
                <b>{{ tp }}</b>
              </h5>
            </b-col>
            <b-col md="8">
              <h5><b>Rights</b></h5>
            </b-col>
          </b-row>
          <hr />
          <div v-for="form in forms[index]" :key="form.name">
            <b-row class="mt-1">
              <b-col md="4">
                <h6>{{ form.name }}</h6>
              </b-col>
              <b-col md="8">
                <b-form-group label="" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    v-bind:id="`checkbox_${form.name}`"
                    v-model="selected"
                    v-bind:options="form.options"
                    :aria-describedby="ariaDescribedby"
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="verticalSeperator"></div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            class="float-right ml-3"
            variant="primary"
            @click="SaveRights()"
          >
            <b-spinner v-if="request" type="grow"></b-spinner>
            <span v-else class="text-nowrap">Save</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- <b-table
        class="mt-1"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #empty="scope">
          <h3 class="mt-1 mb-1" style="text-align: center">No records found</h3>
        </template>
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #head(actions)="data">
          <div class="mr-5 text-right">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Edit(data.item.id)"
            >
              <feather-icon icon="EditIcon" class="" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              class="btn-icon mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="Delete(data.item.id)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </div>
        </template>
      </b-table> -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BForm,
  BMediaAside,
  BAside,
  BSpinner,
  BCardTitle,
  BCardText,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import { forEach } from "postcss-rtl/lib/affected-props";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    //Multiselect,
    BCard,
    BRow,
    BBadge,
    BTable,
    BCol,
    BFormInput,
    BButton,
    BSidebar,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BForm,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {},
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async created() {
    // this.LoadData();
  },
  computed: {
    ...mapGetters({ cmbDepartment: "cmbDepartment" }),
    ...mapGetters({ data: "data" }),
    getColor(ind) {
      console.log("%%%%%% getting color", ind.index);
      return this.color[ind];
    },

    PageChange() {
      console.log(this.currentPage);
      return this.LoadData();
    },
    filters: function () {
      return this.items.filter((pro) => {
        return pro.name.toLowerCase().match(this.searchQuery.toLowerCase());
      });
    },
  },
  data() {
    let forms = [
      [
        { name: "School Info", objName: "School Info" },
        { name: "Class and Section Info", objName: "Class and Section Info" },
        { name: "Student", objName: "Student" },
        { name: "Staff", objName: "Staff" },
        { name: "G.R Register", objName: "G.R Register" },
        { name: "Inquiry", objName: "Inquiry" },
        { name: "Barcode Att", objName: "Barcode Att" },
        { name: "Registeration", objName: "Registeration" },
        { name: "Van Report", objName: "Van Report" },
        { name: "Staff Att", objName: "Staff Att" },
      ],
      [
        { name: "Fees Type", objName: "Fees Type" },
        { name: "Fees Amount", objName: "Fees Amount" },
        { name: "Create Fees", objName: "Create Fees" },
        { name: "Fees Collection", objName: "Fees Collection" },
        { name: "Print Challan", objName: "Print Challan" },
      ],
      [
        { name: "Add User", objName: "Add User" },
        { name: "User Rights", objName: "User Rights" },
        { name: "Change Password", objName: "Change Password" },
        {
          name: "Fees Generation After Reg",
          objName: "Fees Generation After Reg",
        },
        { name: "Salary Info", objName: "Salary Info" },
        { name: "Backup Configuration", objName: "Backup Configuration" },
        { name: "Board Class Config", objName: "Board Class Config" },
        { name: "Board Student", objName: "Board Student" },
        { name: "Exam Att", objName: "Exam Att" },
      ],
      [
        { name: "DCR", objName: "DCR" },
        { name: "Defulters", objName: "Defulters" },
        { name: "Income Report", objName: "Income Report" },
        { name: "Expenses Report", objName: "Expenses Report" },
        { name: "Absentee Report", objName: "Absentee Report" },
        { name: "Collection Summary", objName: "Collection Summary" },
        { name: "Fees Status", objName: "Fees Status" },
        { name: "Free Student", objName: "Free Student" },
        { name: "Cash Book", objName: "Cash Book" },
        { name: "Student List", objName: "Student List" },
        { name: "Other Reports", objName: "Other Reports" },
      ],
      [
        { name: "Define Messages", objName: "Define Messages" },
        { name: "SMS Sender", objName: "SMS Sender" },
        { name: "Manage SMS", objName: "Manage SMS" },
        { name: "SMS Config", objName: "SMS Config" },
        { name: "Sendbox", objName: "Sendbox" },
      ],
      [
        { name: "A/C Heads", objName: "A/C Heads" },
        { name: "Expense Entry", objName: "Expense Entry" },
        { name: "Other Income", objName: "Other Income" },
        { name: "Salary Entry", objName: "Salary Entry" },
      ],
      [
        { name: "School Subject", objName: "School Subject" },
        { name: "Subject Marks", objName: "Subject Marks" },
        { name: "Student Attendance", objName: "Student Attendance" },
        { name: "Certificates", objName: "Certificates" },
        { name: "Awards", objName: "Awards" },
        { name: "Test Attendance", objName: "Test Attendance" },
      ],
      [
        { name: "Exam Type", objName: "Exam Type" },
        { name: "Grading Criteria", objName: "Grading Criteria" },
        { name: "Remarks", objName: "Remarks" },
        { name: "Marks Entry", objName: "Marks Entry" },
        { name: "Marks Entry Sheet", objName: "Marks Entry Sheet" },
        { name: "Aggregate", objName: "Aggregate" },
        { name: "Print result", objName: "Print result" },
      ],
      [
        { name: "COA", objName: "COA" },
        { name: "Journal Entries", objName: "Journal Entries" },
        { name: "Reports", objName: "Reports" },
        { name: "Opening Balance", objName: "Opening Balance" },
      ],
    ];
    let options = [
      { text: "View", value: "view" },
      { text: "Add", value: "add" },
      { text: "Edit", value: "edit" },
      { text: "Delete", value: "delete" },
    ];

    let formPaths = [];
    forms.forEach((el) => {
      el.forEach((p) => {
        formPaths.push(p.objName);
      });
    });
    console.log(formPaths);

    forms.forEach((el, index) => {
      //   console.log(el);
      forms[index] = el.map((f) => {
        // console.log(f);
        return {
          name: f.name,
          objName: f.objName,
          options: options.map((option) => {
            return {
              text: option.text,
              value: `${option.value}_${f.objName}`,
            };
          }),
        };
      });
      //   console.log(forms[index]);
    });
    // forms = forms.map((form) => {
    //   console.log(form);
    //   return [
    //     form.map((f) => {
    //       console.log(f);
    //       return {
    //         name: f.name,
    //         objName: f.objName,
    //         options: options.map((option) => {
    //           return {
    //             text: option.text,
    //             value: `${option.value}_${f.objName}`,
    //           };
    //         }),
    //       };
    //     }),
    //   ];
    // });
    return {
      forms: forms,
      formTypes: [
        "Home",
        "Fees",
        "Configuration",
        "Reports",
        "SMS Sender",
        "Accounts",
        "Education",
        "Examination",
        "Finance",
      ],
      formPaths: formPaths,
      request: false,
      dataLoading: false,
      saveObj: [],
      fields: [
        { label: "username", key: "username" },
        { label: "name", key: "name" },
        { label: "role", key: "role" },
        { key: "actions", label: "actions" },
      ],
      items: [
        {
          username: "user@gmail.com",
          name: "dummy user",
          role: "user",
        },
      ],
      selected: [],
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),
    checkRights() {
      console.log(this.selected);
    },
    SaveRights() {
      console.log("save:", this.selected);
      this.request = true;
      this.selected.forEach((el) => {
        let check = el.split("_");
        let exist = false;
        this.saveObj.forEach((s) => {
          if (check[1] == s.form) {
            exist = true;
            if (check[0] == "view") {
              s.view = true;
            } else if (check[0] == "add") {
              s.add = true;
            } else if (check[0] == "edit") {
              s.edit = true;
            } else if (check[0] == "delete") {
              s.delete = true;
            }
          }
        });
        if (!exist) {
          this.formPaths.forEach((path) => {
            if (check[1] == path) {
              let obj = {
                form: path,
                view: false,
                add: false,
                edit: false,
                delete: false,
              };
              if (check[0] == "view") {
                obj.view = true;
              } else if (check[0] == "view") {
                s.view = true;
              } else if (check[0] == "edit") {
                obj.edit = true;
              } else if (check[0] == "delete") {
                obj.delete = true;
              }
              this.saveObj.push(obj);
            }
          });
        }
      });
      console.log("Obj:", this.saveObj);
      this.request = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
